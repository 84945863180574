<template>
  <b-modal
    id="lesson-section-edit"
    :title="modalTitle"
    size="xl"
    centered
    hide-footer
    @hide="resetModal"
  >
    <b-container>
      <b-overlay :show="loading">
        <form @submit.prevent="handleValidate">
          <b-row>
            <b-col cols="6">
              <basic-input
                :required="true"
                label="Mã học phần"
                name="code"
                placeholder="Nhập mã học phần"
                :value.sync="form.code"
                maxlength="50"
                :state="validateState('code')"
                :invalidFeedback="errors.first('code')"
                v-validate="{
                  required: true,
                  alpha_num: true,
                  valid_code: validCode,
                }"
                data-vv-as="Mã học phần"
              />
              <basic-input
                :required="true"
                label="Tên học phần"
                name="name"
                placeholder="Nhập tên học phần"
                :value.sync="form.name"
                maxlength="100"
                :state="validateState('name')"
                :invalidFeedback="errors.first('name')"
                v-validate="'required'"
                data-vv-as="Tên học phần"
              />
              <basic-select
                :required="true"
                label="Loại"
                placeholder="---Chọn loại---"
                name="type"
                :solid="false"
                :value.sync="form.type"
                :options="typeOptions"
                value-label="text"
                track-by="value"
                :state="validateState('type')"
                :invalidFeedback="errors.first('type')"
                v-validate="'required'"
                data-vv-as="Loại học phần"
                @update:value="selectedType"
              />
              <basic-select
                :required="true"
                label="Trạng thái"
                placeholder="---Chọn trạng thái---"
                disabled
                name="status"
                :solid="false"
                :value.sync="form.status"
                :options="stateOptions"
                value-label="text"
                track-by="value"
                :state="validateState('status')"
                :invalidFeedback="errors.first('status')"
                v-validate="'required'"
                data-vv-as="Trạng thái"
                @update:value="selected($event, 'status', stateOptions)"
              />
            </b-col>
            <b-col cols="6">
              <div v-if="!form.type"></div>
              <div v-else-if="form.type.value == 4">
                <div style="font-weight: bold; margin-bottom: 16px">
                  <span>Tổng số câu hỏi thỏa mãn: </span>
                  <span>{{ form.questionNum }}</span>
                </div>
                <basic-select
                  :required="true"
                  label="Số câu hỏi"
                  name="questionNum"
                  placeholder="---Chọn số câu hỏi---"
                  :options="getQuestionNumOptions(form.questionNum)"
                  :value.sync="form.selectedQuestionNum"
                  value-label="text"
                  track-by="value"
                  :state="validateState('questionNum')"
                  :invalidFeedback="errors.first('questionNum')"
                  v-validate="'required'"
                  data-vv-as="Số câu hỏi"
                  @update:value="selectedQuestionCount"
                />
                <div class="d-flex justify-content-end">
                  <b-button
                    class="btn lesson-section-edit-page__btn-info ml-2"
                    :disabled="!form.selectedQuestionNum"
                    type="button"
                    @click="onClickPreviewQuizButton"
                  >
                    <span class="svg-icon">
                      <inline-svg src="/media/svg/icons/Neolex/Basic/eye.svg" />
                    </span>
                    Xem trước
                  </b-button>
                </div>
              </div>
              <div v-else>
                <div>
                  <label style="font-weight: bold">Text</label>
                  <vue-editor
                    id="editor"
                    useCustomImageHandler
                    @image-added="handleImageAdded"
                    v-model="form.firstContent"
                  >
                  </vue-editor>
                  <!-- <basic-text-editors
                    :value.sync="form.firstContent"
                    placeholder="Nhập text"
                  /> -->
                </div>
                <div>
                  <label style="font-weight: bold">Hình ảnh</label>
                  <div class="image-upload">
                    <v-file-input
                      hide-input
                      v-model="form.imageFile"
                      accept="image/png, image/jpeg"
                      class="icon-edit"
                      @change="onUpload"
                      prepend-icon="mdi-pencil-outline"
                      truncate-length="15"
                    ></v-file-input>
                    <v-img
                      :src="form.imagePreview"
                      :aspect-ratio="2 / 1"
                    ></v-img>
                  </div>
                  <div class="error text-error">
                    <p v-if="!isImageValid" class="mb-1">
                      Kích thước ảnh tối đa nhỏ hơn 512kb
                    </p>
                    <p v-if="!isImage" class="mb-1">
                      Định dạng ảnh phải là JPG hoặc PNG
                    </p>
                  </div>
                  <div class="p-2">
                    <basic-input
                      placeholder="Nhập title hình ảnh"
                      maxlength="200"
                      :value.sync="form.imageTitle"
                    />
                  </div>
                </div>
                <div>
                  <label style="font-weight: bold">Text</label>
                  <vue-editor
                    id="editor2"
                    useCustomImageHandler
                    @image-added="handleImageAdded"
                    v-model="form.secondContent"
                  >
                  </vue-editor>
                  <!-- <basic-text-editors
                    :value.sync="form.secondContent"
                    placeholder="Nhập text"
                  /> -->
                </div>
                <div>
                  <label style="font-weight: bold">Video</label>
                  <div class="pl-2 pr-2 pb-4">
                    <basic-file-input
                      v-if="!form.videoUrl"
                      title="Video"
                      @uploaded="handleUploaded"
                    >
                      <template v-slot:uploadedFile>
                        <video
                          v-if="videoPreview"
                          :src="videoPreview"
                          class="w-100"
                          controls
                        ></video>
                      </template>
                    </basic-file-input>
                  </div>
                  <div class="p-2">
                    <div v-if="!videoPreview">
                      <basic-input
                        name="videoUrl"
                        placeholder="---Nhập đường dẫn video---"
                        :value.sync="form.videoUrl"
                        :state="validateState('videoUrl')"
                        :invalidFeedback="errors.first('videoUrl')"
                        v-validate="'valid_video_url'"
                        data-vv-as="Đường dẫn video"
                      >
                      </basic-input>
                      <video
                        v-if="form.videoUrl && !isValidYoutubeLink()"
                        :src="form.videoUrl"
                        class="w-100 mb-4"
                        controls
                      ></video>
                      
                      <iframe v-if="form.videoUrl && isValidYoutubeLink()"
                        class="w-100 mb-4"
                        height="315"
                        :src="youtubeEmbedUrl"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <basic-input
                      placeholder="Nhập title video"
                      maxlength="200"
                      :value.sync="form.videoTitle"
                    />
                  </div>
                </div>
                <div>
                  <label style="font-weight: bold">Audio</label>
                  <div class="pl-2 pr-2 pb-4">
                    <basic-file-input
                      v-if="!form.audioUrl"
                      title="Audio"
                      type="audio"
                      @uploaded="handleUploaded"
                    >
                      <template v-slot:uploadedFile>
                        <audio
                          v-if="audioPreview"
                          :src="audioPreview"
                          class="w-100"
                          controls
                        ></audio>
                      </template>
                    </basic-file-input>
                  </div>
                  <div class="p-2">
                    <template v-if="!audioPreview">
                      <basic-input
                        placeholder="Nhập đường dẫn audio"
                        :value.sync="form.audioUrl"
                        name="audioUrl"
                        :state="validateState('audioUrl')"
                        :invalidFeedback="errors.first('audioUrl')"
                        v-validate="'valid_audio_url'"
                        data-vv-as="Đường dẫn audio"
                      />
                      <audio
                        v-if="form.audioUrl"
                        :src="form.audioUrl"
                        class="w-100 mb-4"
                        controls
                      ></audio>
                    </template>
                    <basic-input
                      placeholder="Nhập title audio"
                      maxlength="200"
                      :value.sync="form.audioTitle"
                    />
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-end align-items-center">
            <b-button class="btn mr-3" @click="onClickCancelButton">
              Hủy</b-button
            >
            <b-button
              v-if="
                !isCreateMode &&
                form.id &&
                form.status &&
                form.status.value == 1
              "
              class="btn btn-inactive mr-3"
              @click="showPopupChangeStatus(0)"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
              </span>
              Inactive
            </b-button>
            <b-button
              v-else-if="
                !isCreateMode &&
                form.id &&
                form.status &&
                form.status.value != 1
              "
              class="btn btn-active mr-3"
              type="button"
              @click="showPopupChangeStatus(1)"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
              </span>
              Active
            </b-button>
            <b-button class="btn btn-success" type="submit">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
              </span>
              Lưu
            </b-button>
          </div>
        </form>
      </b-overlay>
      <lesson-quiz-preview
        id="lesson-section-quiz-review"
        :title="`Học phần Quiz ${form.name || ''}`"
        :question-list="questionList"
        :question-num="
          form.selectedQuestionNum && form.selectedQuestionNum.value
        "
      />
    </b-container>
  </b-modal>
</template>

<script>
import { MODAL_TYPE } from '@/core/plugins/constants';
import BasicFileInput from './BasicFileInput';
import LessonQuizPreview from './LessonQuizPreview';
import { VueEditor } from 'vue2-editor';
export default {
  name: 'LessonSectionEdit',
  components: { LessonQuizPreview, BasicFileInput, VueEditor },
  props: {
    section: {
      type: Object,
      default: () => {
        return {};
      },
    },
    lessionId: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: MODAL_TYPE.DETAIL,
    },
  },
  created() {
    this.loadPrepareData();
  },
  data() {
    return {
      url: {
        detail: '/LessonSection',
        prepare: '/LessonSection/PrepareCreateUpdateFormItem',
        changeState: '/LessonSection/{id}/State',
        getQuiz: '/Quiz',
      },
      isImageValid: true,
      isImage: true,
      stateOptions: [],
      typeOptions: [],
      form: {
        id: null,
        code: null,
        name: null,
        type: null,
        status: null,
        firstContent: null,
        secondContent: null,
        videoFile: null,
        videoUrl: null,
        linkType: 0,
        videoTitle: null,
        audioFile: null,
        audioUrl: null,
        audioTitle: null,
        imageFile: null,
        imageTitle: null,
        imagePreview: null,
        minCompletePercent: null,
        questionNum: 0,
        selectedQuestionNum: null,
        originalQuestionList: [],
        fileVideo: null,
        fileAudio: null,
      },
      questionList: [],
      videoPreview: null,
      audioPreview: null,
      loading: false,
      validCode: false,
    };
  },
  watch: {
    section: {
      deep: true,
      handler(val) {
        if (val.id) {
          this.loadPrepareDataSection();
          this.loadQuestionNumOptions(this.lessionId);
        } else {
          this.form = {
            ...this.form,
            ...val,
            type: this.typeOptions.find((type) => type.value == val.type),
            status: this.stateOptions.find(
              (status) => status.value === val.status || '2',
            ),
          };
        }
      },
    },
    lessionId: {
      handler(lessionId) {
        this.form.questionNum = 0;
        this.form.selectedQuestionNum = null;
        this.loadQuestionNumOptions(lessionId);
        this.getQuestionNumOptions(lessionId);
      },
    },
    'form.code': function (val) {
      const code = this.section.code;
      if (!val || code === val) return (this.validCode = false);
      this.checkDuplicateCode(val);
    },
  },
  computed: {
    modalTitle() {
      switch (this.type) {
        case MODAL_TYPE.CREATE:
          return 'Tạo học phần mới';
        case MODAL_TYPE.EDIT:
          return 'Chỉnh sửa học phần';
        default:
          return '';
      }
    },
    isCreateMode() {
      return this.type === MODAL_TYPE.CREATE;
    },
    youtubeEmbedUrl() {
      return `https://www.youtube.com/embed/${this.getVideoIdFromUrl()}`;
    },
  },
  methods: {
    resetModal() {
      this.form = {
        id: null,
        code: null,
        name: null,
        type: null,
        status: null,
        firstContent: null,
        secondContent: null,
        videoFile: null,
        videoUrl: null,
        linkType: 0,
        videoTitle: null,
        audioFile: null,
        audioUrl: null,
        audioTitle: null,
        imageFile: null,
        imageTitle: null,
        imagePreview: null,
        minCompletePercent: null,
        questionNum: 0,
        selectedQuestionNum: null,
        originalQuestionList: [],
        fileVideo: null,
        fileAudio: null,
      };
      this.isImageValid = true;
      this.isImage = true;
      this.videoPreview = null;
      this.audioPreview = null;
      this.loading = false;
      this.validCode = false;
    },
    onClickPreviewQuizButton() {
      this.$bvModal.show('lesson-section-quiz-review');
    },
    async getQuizList(lessionId, size) {
      try {
        this.loading = true;
        return await this.$api.get(this.url.getQuiz, {
          params: {
            lessonIdList: lessionId,
            status: 1,
            size: size,
          },
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    getQuestionNumOptions(val) {
      let numArr = [];
      if (val) {
        for (let i = 0; i < val; i++) {
          numArr.push({
            text: i + 1,
            value: i + 1,
          });
        }
      }
      return numArr;
    },
    getUrlFromFile(file) {
      if (file) {
        let url = window.URL || window.webkitURL;
        let src = url.createObjectURL(file);
        return src;
      }
      return null;
    },
    closeModal() {
      this.$bvModal.hide('lesson-section-edit');
    },
    onClickCancelButton() {
      this.closeModal();
    },
    showPopupChangeStatus(state) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          state ? 'Active' : 'Inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            this.onClickToggleStatusButton(state);
          }
        }.bind(this),
      );
    },
    async onClickToggleStatusButton(state) {
      if (!this.section.id) return;
      try {
        this.loading = true;
        const res = await this.$api.put(
          this.url.changeState.replace('{id}', this.section.id),
          {
            id: this.section.id,
            state: state,
          },
        );
        if (res.error) {
          this.loading = false;
          this.$toastr.e({
            title: 'Lỗi',
            msg: res.error.message,
          });
          return;
        }
        this.loadStatusData();
        this.$emit('change-status');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    isValidYoutubeLink() {
      const youtubeRegex = /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
      const isValidYoutubeLink = youtubeRegex.test(this.form.videoUrl);
      return isValidYoutubeLink;
    },
    getVideoIdFromUrl() {
      const match = this.form.videoUrl.match(/[?&]v=([^&]+)/);
      return match ? match[1] : null;
    },
    async onClickSaveButton() {
      const params = {
        ...this.form,
        linkType: this.isValidYoutubeLink() ? 1 : 0
      };
      console.log(params)
      if (this.questionList.length) {
        params['questionList'] = this.questionList;
      }
      if (this.type === MODAL_TYPE.CREATE) {
        this.$emit('create', params);
      } else if (this.type === MODAL_TYPE.EDIT) {
        this.$emit('edit', params);
      }
      this.closeModal();
    },
    getDropdownOptions(array) {
      return array.map((item) => {
        return {
          text: item.text,
          value: item.value,
        };
      });
    },
    getSelectedValueDropdown(array) {
      let selectedValue = array.find((el) => el.selected === true);
      return selectedValue
        ? {
            text: selectedValue.text,
            value: selectedValue.value,
          }
        : null;
    },
    selected(value, formProperty, optionsArr) {
      this.form[formProperty] = value
        ? optionsArr.find((el) => el.value == value)
        : null;
    },
    loadQuestionNumOptions(lessionId) {
      if (lessionId) {
        this.getQuizList(lessionId).then((res) => {
          this.form.questionNum = res.data?.data.length || 0;
        });
      }
    },
    async loadPrepareData() {
      let { data } = await this.$api.get(this.url.prepare);
      this.stateOptions = this.getDropdownOptions(data.lessonSectionStates);
      this.typeOptions = this.getDropdownOptions(data.lessonSectionTypes);
    },
    async loadStatusData() {
      let { data } = await this.$api.get(
        `${this.url.prepare}/${this.section.id}`,
      );
      this.form.status = this.getSelectedValueDropdown(
        data.lessonSectionStates,
      );
    },
    async loadPrepareDataSection() {
      let { data } = await this.$api.get(
        `${this.url.prepare}/${this.section.id}`,
      );
      this.stateOptions = this.getDropdownOptions(data.lessonSectionStates);
      this.typeOptions = this.getDropdownOptions(data.lessonSectionTypes);

      this.form.id = data.id;
      this.form.code = data.code;
      this.form.name = data.name;
      this.form.status = this.getSelectedValueDropdown(
        data.lessonSectionStates,
      );
      this.form.type = this.typeOptions.find((type) => type.value == data.type);
      this.form.firstContent = data.firstContent;
      this.form.secondContent = data.secondContent;
      this.form.videoUrl = data.videoAddressLink;
      this.form.videoTitle = data.videoDescription;
      this.form.audioUrl = data.audioAddressLink;
      this.form.audioTitle = data.audioDescription;
      this.form.imageTitle = data.imageTitle;
      this.form.imagePreview = data.image.url;

      // load quiz
      if (this.lessionId) {
        let quizRes = await this.$api.get(
          `${this.url.detail}/${this.section.id}`,
        );
        let quizLessonSections = quizRes.data.quizLessonSections;
        this.questionList = quizLessonSections.map((q) => q.quiz) || [];
        this.form.originalQuestionList = [...this.questionList];
        let selectedQuestionNum = quizLessonSections
          ? quizLessonSections.length
          : 0;
        this.form.selectedQuestionNum = selectedQuestionNum
          ? this.getQuestionNumOptions(quizLessonSections.length).find(
              (num) => num.value === selectedQuestionNum,
            )
          : null;
      }
    },
    async handleValidate() {
      await (this.validCode = false);
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.onClickSaveButton();
        }
        return;
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    onUpload() {
      this.isImage = ['image/jpeg', 'image/png'].includes(
        this.form.imageFile.type,
      );
      this.isImageValid = this.form.imageFile.size <= 512000;
      if (this.isImage && this.isImageValid) {
        return (this.form.imagePreview = URL.createObjectURL(
          this.form.imageFile,
        ));
      }
      return (this.form.imagePreview = null);
    },
    handleUploaded({ url, file, type }) {
      if (type === 'video') {
        this.videoPreview = url;
        this.form.fileVideo = file;
      } else {
        this.audioPreview = url;
        this.form.fileAudio = file;
      }
    },
    async checkDuplicateCode(code) {
      try {
        const { meta, error, data } = await this.$api.get(
          '/LessonSection/CheckDuplicateCode',
          {
            params: {
              code,
            },
          },
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.validCode = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
    },
    selectedType(event) {
      this.selected(event, 'type', this.typeOptions);
      if (event === '4') this.loadQuestionNumOptions(this.lessionId);
      this.form.firstContent = null;
      this.form.secondContent = null;
      this.form.videoFile = null;
      this.form.videoUrl = null;
      this.form.videoTitle = null;
      this.form.audioFile = null;
      this.form.audioUrl = null;
      this.form.audioTitle = null;
      this.form.imageFile = null;
      this.form.imageTitle = null;
      this.form.imagePreview = null;
      this.form.fileVideo = null;
      this.form.fileAudio = null;
      this.videoPreview = null;
      this.audioPreview = null;
    },
    selectedQuestionCount(event) {
      this.selected(
        event,
        'selectedQuestionNum',
        this.getQuestionNumOptions(this.form.questionNum),
      );
      if (this.lessionId && this.form.selectedQuestionNum) {
        this.getQuizList(
          this.lessionId,
          this.form.selectedQuestionNum.value,
        ).then((res) => {
          this.questionList = res.data.data;
        });
      }
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_IMAGE',
        file,
      );
      if (error) {
        this.$toastr.s({
          title: 'Lỗi !',
          msg: error,
        });
        return null;
      }
      Editor.insertEmbed(cursorLocation, 'image', data.url);
      resetUploader();
    },
  },
};
</script>

<style lang="scss">
.image-upload {
  width: 240px;
  height: 120px;
  background-color: #f3f6f9;
  box-shadow: 0px 4px 4px 0px #000000 10%;
  position: relative;
  margin: auto;
}

.text-error {
  color: #ff5756;
  text-align: center;
  margin-top: 20px;
}

.pictureInput {
  position: relative;
}

.icon-edit {
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 18px);
  padding: 2px 6px;
  box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #fff;
}

.media-upload {
  height: 67px;
  border: 2px dashed #dddddd;

  span {
    color: #787a7d;
    font-size: 16px;
  }
}

.lesson-section-edit-page__btn-info,
.lesson-section-edit-page__btn-info:hover,
.lesson-section-edit-page__btn-info:active {
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}
</style>
