<template>
  <div>
    <div
      class="file-input-dashed d-flex justify-content-center align-items-center cursor-pointer mb-5"
      :class="{ 'file-input-invalid': invalidFeedback }"
      @click="$refs.fileUpload.click()"
    >
      <span>{{ title }}</span>
      <input
        v-show="false"
        id="fileUpload"
        ref="fileUpload"
        type="file"
        :accept="accept"
        @change="onFileChanged"
      />
    </div>
    <div>
      <slot name="uploadedFile" />
    </div>
    <div v-if="invalidFeedback" class="invalid-feedback d-block">
      {{ invalidFeedback }}
    </div>
  </div>
</template>

<script>
import {
  ALLOW_FILE_VIDEO_TYPE,
  ALLOW_FILE_AUDIO_TYPE,
} from '@/core/plugins/constants';
export default {
  name: 'BasicFileInput',
  props: {
    title: {
      type: String,
      default: '',
    },
    valid: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'video',
    },
    invalidFeedback: {
      type: String,
      defaultl: null,
    },
  },
  computed: {
    accept() {
      return this.type === 'video' ? '.mov, .mp4, .avi' : '.mp3, .wav';
    },
  },
  methods: {
    onFileChanged(event) {
      const file = event.target.files[0];

      if (this.type === 'video') {
        if (!ALLOW_FILE_VIDEO_TYPE.includes(file.type)) {
          return this.$toastr.e({
            title: 'Lỗi',
            msg: 'Định dạng video phải là MP4, MOV, AVI',
            preventDuplicates: true,
          });
        }

        if (file.size > 100 * 1024 * 1024) {
          return this.$toastr.e({
            title: 'Lỗi',
            msg: 'Kích thước video tối đa nhỏ hơn 100MB',
            preventDuplicates: true,
          });
        }
      } else {
        if (!ALLOW_FILE_AUDIO_TYPE.includes(file.type)) {
          return this.$toastr.e({
            title: 'Lỗi',
            msg: 'Định dạng audio phải là MP3, WAV',
            preventDuplicates: true,
          });
        }

        if (file.size > 100 * 1024 * 1024) {
          return this.$toastr.e({
            title: 'Lỗi',
            msg: 'Kích thước audio tối đa nhỏ hơn 100MB',
            preventDuplicates: true,
          });
        }
      }

      const url = URL.createObjectURL(file);
      event.target.value = '';
      this.$emit('uploaded', {
        url,
        file,
        type: this.type,
      });
    },
  },
};
</script>

<style lang="scss">
.file-input-dashed {
  height: 67px;
  border: 2px dashed #dddddd;

  &.file-input-invalid {
    border-color: red;
  }

  span {
    font-size: 16px;
    color: #787a7d;
  }
}
</style>
