<template>
  <b-container fluid class="lesson-detail-page py-6">
    <b-overlay :show="loading">
      <b-row>
        <b-col cols="3">
          <div class="card card-custom gutter-b lesson-detail-page__left-panel">
            <div class="card-body p-0">
              <b-card
                overlay
                class="lesson-detail-page__left-panel__image-card"
                :img-src="lessonData.image"
                :img-height="200"
              >
                <b-card-text
                  class="lesson-detail-page__left-panel__image-card-title"
                >
                  {{ lessonData.name }}
                </b-card-text>
              </b-card>
              <div class="lesson-detail-page__left-panel__actions">
                <b-container>
                  <b-row>
                    <b-col>
                      <b-button
                        v-if="isWritePermission"
                        class="mr-4 btn-edit"
                        size="sm"
                        @click="onClickEditButton"
                      >
                        <span class="svg-icon svg-icon-sm mr-0">
                          <inline-svg
                            class="svg-icon mr-0"
                            src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                          />
                        </span>
                      </b-button>
                      <span
                        class="status"
                        :class="getStatus(lessonData.status)"
                      >
                        {{ getStatus(lessonData.status) }}
                      </span>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
              <div class="lesson-detail-page__left-panel__information">
                <b-container>
                  <b-row>
                    <b-col>
                      <div class="text-muted">Mã bài học</div>
                      <div>{{ lessonData.code }}</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-muted">Số lượng học phần</div>
                      <div>
                        <span class="svg-icon svg-icon-xl">
                          <inline-svg
                            class="svg-icon"
                            src="/media/svg/icons/Neolex/Basic/Library.svg"
                          />
                        </span>
                        {{ lessonData.totalSection }} phần
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-muted">Cấp độ</div>
                      <div>{{ lessonData.levelName }}</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-muted">Chủ đề</div>
                      <div>{{ lessonData.module }}</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-muted">Mô tả</div>
                      <div>{{ lessonData.description }}</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-muted">Từ khóa</div>
                      <div>{{ lessonData.tags }}</div>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </div>
        </b-col>
        <b-col>
          <b-card class="h-100">
            <b-tabs
              active-tab-class="p-0"
              card
              class="lesson-detail-page__right-panel__tabs"
            >
              <b-tab>
                <template #title>
                  <span>Chi tiết bài học</span>
                </template>
                <div class="py-6">
                  <template-table
                    v-if="lessonData.type !== 3"
                    :column="column"
                    :data="lessonSectionsData"
                    :tableAction="false"
                    :selectAction="false"
                    :searchAction="false"
                    :pagingAction="false"
                    @search="searchRequest"
                    @reset="resetRequest"
                    @sortBy="sortRequest"
                  >
                    <template v-slot:body="{ item }">
                      <td>{{ item.code }}</td>
                      <td>{{ item.name }}</td>
                      <td style="width: 20px">
                        <action-dropdown
                          :value="item"
                          :show_copy="false"
                          :show_delete="false"
                          :show_edit="isWritePermission"
                          boundary="window"
                          @view="onClickViewSectionButton(item)"
                          @edit="onClickEditSectionButton(item)"
                        >
                          <template v-if="isWritePermission">
                            <b-dropdown-text
                              v-if="item.status === 1"
                              tag="div"
                              class="navi-item cursor-pointer"
                            >
                              <a
                                class="navi-link text-danger"
                                @click="showPopupChangeStatus(item, 0)"
                              >
                                <span class="menu-icon svg-icon svg-icon-sm">
                                  <inline-svg
                                    class="svg-icon"
                                    src="/media/svg/icons/Neolex/Basic/power.svg"
                                  />
                                </span>
                                <span class="navi-text text-danger">
                                  Inactive
                                </span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text
                              v-else
                              tag="div"
                              class="navi-item cursor-pointer"
                            >
                              <a
                                class="navi-link text-primary"
                                @click="showPopupChangeStatus(item, 1)"
                              >
                                <span class="menu-icon svg-icon svg-icon-sm">
                                  <inline-svg
                                    class="svg-icon"
                                    src="/media/svg/icons/Neolex/Basic/power.svg"
                                  />
                                </span>
                                <span class="navi-text text-primary">
                                  Active
                                </span>
                              </a>
                            </b-dropdown-text>
                          </template>
                        </action-dropdown>
                      </td>
                      <td>
                        <div>
                          <span class="status" :class="getStatus(item.status)">
                            {{ getStatus(item.status) }}
                          </span>
                        </div>
                      </td>
                    </template>
                  </template-table>
                  <div v-else class="p-6">
                    <b-row>
                      <b-col cols="3">
                        <div class="text-muted">Số câu hỏi:</div>
                        <div class="font-weight-bolder">
                          {{ lessonSectionsData.length }}
                        </div>
                      </b-col>
                      <b-col>
                        <div class="text-muted">
                          % tối thiểu để hoàn thành bài học:
                        </div>
                        <div class="font-weight-bolder">
                          {{ lessonData.minCompletePercent }}%
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-tab>
              <!-- <b-tab>
                  <template #title>
                    <span>Thống kê đánh giá</span>
                  </template>
                  <lesson-detail-rating />
                </b-tab> -->
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <lesson-section-edit
      :section="selectedSection"
      :type="modalType"
      :level="lessonData.level"
      @edit="callbackEditLessonSection"
    />
    <lesson-section-detail :section="selectedSection" />
  </b-container>
</template>

<script>
import { MODAL_TYPE } from '@/core/plugins/constants';
// import LessonDetailRating from './LessonDetailRating';
import LessonSectionDetail from './LessonSectionDetail';
import LessonSectionEdit from './LessonSectionEdit';

export default {
  name: 'LessonDetail',
  components: {
    LessonSectionEdit,
    LessonSectionDetail,
    //  LessonDetailRating
  },
  data() {
    return {
      url: {
        changeState: '/LessonSection/{id}/State',
        detail: '/Lesson',
        prepareSection: '/LessonSection/PrepareCreateUpdateFormItem/{id}',
        editSection: '/LessonSection',
      },
      selectedSection: {},
      modalType: MODAL_TYPE.EDIT,
      lessonData: {
        code: null,
        status: true,
        name: null,
        totalSection: 0,
        description: null,
        module: null,
        type: null,
        level: null,
        levelName: null,
        order: null,
        activeRating: false,
        tags: null,
        image: null,
        minCompletePercent: null,
      },
      lessonSectionsData: [],
      sort: {
        by: null,
        order: null,
      },
      loading: false,
    };
  },
  watch: {
    sort: {
      handler(val) {
        this.sortArray(this.lessonSectionsData, val.by, val.order);
      },
      deep: true,
    },
  },
  computed: {
    lessonId() {
      return this.$route.params.id;
    },
    getTitleHeaderCode() {
      if (this.lessonData.type === 3) return 'Mã bài học';
      return 'Mã học phần';
    },
    getTitleHeaderName() {
      if (this.lessonData.type === 3) return 'Tên bài học';
      return 'Tên học phần';
    },
    column() {
      return [
        {
          key: 'code',
          label: this.getTitleHeaderCode,
          sortable: true,
        },
        {
          key: 'name',
          label: this.getTitleHeaderName,
          sortable: true,
          style: {
            width: '50%',
          },
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
        {
          key: 'isActive',
          label: 'Trạng thái',
          sortable: false,
          style: {
            width: '15%',
          },
        },
      ];
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    onClickViewSectionButton(section) {
      this.$api
        .get(this.url.prepareSection.replace('{id}', section.id))
        .then((res) => {
          this.selectedSection = res.data;
          this.openLessonSectionDetailModal();
        });
    },
    onClickEditSectionButton(section) {
      this.$api
        .get(this.url.prepareSection.replace('{id}', section.id))
        .then((res) => {
          this.selectedSection = res.data;
          this.openLessonSectionEditModal();
        });
    },
    async callbackEditLessonSection(section) {
      let imageId = section.imageFile
        ? await this.uploadImage(section.imageFile)
        : null;
      let sectionParams = {
        code: section.code,
        name: section.name,
        lessonId: this.lessonId,
        ...(imageId && { imageId: imageId }),
        type: section.type.value,
        status: section.status.value,
        firstContent: section.firstContent,
        secondContent: section.secondContent,
        videoAddressLink: section.videoUrl,
        videoDescription: section.videoTitle,
        audioAddressLink: section.audioUrl,
        audioDescription: section.audioTitle,
      };
      await this.$api.put(
        `${this.url.editSection}/${section.id}`,
        sectionParams,
      );
      this.loadData();
    },
    openLessonSectionEditModal() {
      this.$bvModal.show('lesson-section-edit');
    },
    openLessonSectionDetailModal() {
      this.$bvModal.show('lesson-section-detail');
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'inactive';
        case 1:
          return 'active';
        default:
          return 'draft';
      }
    },
    sortArray(arr, by, order) {
      if (order === 'asc') {
        arr.sort((a, b) => {
          if (a[by] < b[by]) return -1;
          if (a[by] > b[by]) return 1;
          return 0;
        });
      } else if (order === 'desc') {
        arr.sort((a, b) => {
          if (a[by] < b[by]) return 1;
          if (a[by] > b[by]) return -1;
          return 0;
        });
      }
    },
    onClickEditButton() {
      this.$router.push({
        name: 'lesson_edit',
        params: {
          id: this.$route.params.id,
        },
      });
    },
    searchRequest() {},
    resetRequest() {},
    sortRequest(sortData) {
      this.sort = {
        by: sortData.column,
        order: sortData.order,
      };
      return;
    },
    showPopupChangeStatus(item, state) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          state ? 'Active' : 'Inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            this.onClickToggleStatusButton(item, state);
          }
        }.bind(this),
      );
    },
    async onClickToggleStatusButton(item, state) {
      try {
        this.$store.commit('context/setLoading', true);
        const { error } = await this.$api.put(
          this.url.changeState.replace('{id}', item.id),
          {
            id: item.id,
            state: state,
          },
        );
        if (error) {
          this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        } else {
          this.$toastr.s({
            title: 'Thành công!',
            msg: 'Cập nhật thành công',
          });
          this.loadData();
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    async loadData() {
      this.loading = true;
      try {
        const res = await this.$api.get(`${this.url.detail}/${this.lessonId}`);
        let data = res.data;

        this.lessonData.code = data.code;
        this.lessonData.name = data.name;
        this.lessonData.description = data.description;
        this.lessonData.minCompletePercent = data.minCompletePercent;
        this.lessonData.module = data.lessonModule?.name;
        this.lessonData.level = data.lessonLevelId;
        this.lessonData.levelName = data.lessonLevel?.name;
        this.lessonData.tags = data.lessonTagMappings
          .map((item) => item.tag?.name)
          .join(', ');
        this.lessonData.totalSection = data.lessonSections.length;
        this.lessonData.status = data.status;
        this.lessonData.order = data.order;
        this.lessonData.type = data.type;
        this.lessonData.image = data.image?.url;

        this.lessonSectionsData =
          data.type !== 3
            ? data.lessonSections.map((section) => {
                return {
                  code: section.code,
                  id: section.id,
                  name: section.name,
                  status: section.status,
                };
              })
            : data.quizLessons.map((item) => {
                return {
                  code: item.quiz.code,
                  id: item.quiz.id,
                  name: item.quiz.name,
                  status: item.quiz.status,
                };
              });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    async uploadImage(file) {
      // upload file
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_IMAGE',
        file,
      );
      if (error) {
        this.$toastr.s({
          title: 'Lỗi !',
          msg: error,
        });
        return null;
      }
      return data.id;
    },
  },
};
</script>

<style lang="scss">
.lesson-detail-page {
  .lesson-detail-page__left-panel {
    height: 100%;

    .lesson-detail-page__left-panel__image-card {
      margin-bottom: 20px;
      height: 200px;

      .card-body {
        padding: 0;
        min-height: 200px;
      }

      .lesson-detail-page__left-panel__image-card-title {
        height: 60px;
        width: 100%;
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        padding: 10px;
        overflow: hidden;
      }
    }

    .lesson-detail-page__left-panel__actions {
      display: flex;
      align-items: center;

      .lesson-detail-page__left-panel__actions__button {
        height: 30px;
        width: 30px;

        .lesson-detail-page__left-panel__actions__button__icon {
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  .lesson-detail-page__right-panel {
    height: 100%;
  }
}

.lesson-detail-page__right-panel__tabs {
  a.nav-link {
    border: 0;

    &.active {
      font-weight: 600;
      color: #01645a !important;
      border-bottom: 2px solid #01645a;
    }
  }

  li.nav-item {
    a.nav-link:hover {
      color: #333;
    }
  }
}

.status {
  padding: 6px 24px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }

  &.draft {
    background: #888c9f;
  }
}

.btn-edit {
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}
</style>
